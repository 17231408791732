<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>Filters</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <!-- <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>App section</label>
                    <v-select v-model="form.app_section_id.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="getAppSections1" label="summary" :clearable="true" input-id="app_section" />
                </b-col> -->
                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Media Type</label>
                    <v-select v-model="form.featureable_type.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="Media Type" :options="appSectionTypes" :clearable="true" input-id="media-type" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>link</label>
                    <b-form-input id="link" v-model="form.link.val" class="d-inline-block mr-1" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="danger" class="mt-1" @click="filterSpecialItems"> Filter </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import useSpecialItemList from "@/views/apps/special-item/useSpecialItemList";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        vSelect,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters("appSection", ["getAppSections"]),
        getAppSections1(){
          return this.getAppSections.filter((item) => {
                return ["videos"].includes(item.summary);
            });
        }
    },
    props: {
        roleFilter: {
            type: [String, null],
            default: null,
        },
        planFilter: {
            type: [String, null],
            default: null,
        },
        statusFilter: {
            type: [String, null],
            default: null,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        planOptions: {
            type: Array,
            required: true,
        },
        statusOptions: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const isLoading = ref();
        const appSectionTypes = ref(["music", "video", "radio_station", "playlist", "news"]);

        const {
            fetchSpecialItem,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            specialItems,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = useSpecialItemList();

        const blankForm = {
            app_section_id: {
                type: "like",
                val: 6,
            },
            featureable_type: {
                type: "like",
                val: null,
            },
            link: {
                type: "like",
                val: null,
            },
        };
        const form = ref(blankForm);

        return {
            form,
            isLoading,
            // Sidebar
            fetchSpecialItem,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            specialItems,
            appSectionTypes,
            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
    methods: {
        ...mapActions("appSection", ["loadAppSection"]),
        filterSpecialItems() {
            this.$emit("filter", { page: 1, itemsPerPage: this.perPage }, this.form);
        },
    },
    created() {
        this.loadAppSection()
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
